@import '../variables';

$prefix: $antPrefix + -skeleton;
.#{$prefix} {
  .#{$prefix}-input,
  .#{$prefix}-avatar,
  .#{$prefix}-button,
  .#{$prefix}-title,
  .#{$prefix}-paragraph > li {
    border-radius: 4px;
  }
}
[data-theme='dark'] {
  .#{$prefix} {
    &:not(.#{$prefix}-active) {
      .#{$prefix}-input,
      .#{$prefix}-avatar,
      .#{$prefix}-button,
      .#{$prefix}-title,
      .#{$prefix}-paragraph > li {
        background: rgba(207, 216, 220, 0.2);
      }
    }

    &.#{$prefix}-active {
      .#{$prefix}-input,
      .#{$prefix}-avatar,
      .#{$prefix}-button,
      .#{$prefix}-title,
      .#{$prefix}-paragraph > li {
        background-image: linear-gradient(90deg, rgba(67, 70, 72, 0.2) 25%, rgba(67, 70, 72, 0.4) 37%, rgba(67, 70, 72, 0.2) 63%);
      }
    }
  }
}

.#{$antPrefix}-card .#{$antPrefix}-card-loading-block {
  background-image: linear-gradient(90deg, rgba(207, 216, 220, 0.2) 25%, rgba(207, 216, 220, 0.4) 37%, rgba(207, 216, 220, 0.2) 63%);
  animation: ant-skeleton-loading 1.4s ease infinite;
}
