@import '../variables';

.PhoneInputInput {
  border: none;
  outline: none;
  background: transparent;
}
.PhoneInputCountryIconImg {
  margin-top: -10px;
}
:root {
  --PhoneInput-color--focus: #{$primaryColor};
}

.PhoneInputInput:disabled {
  color: $disabledColor;
  background-color: $bodyBackground;
  border-color: $borderColor;
  &:hover {
    border-color: $borderColor;
  }
}
