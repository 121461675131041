$antPrefix: ant;
$primaryColor: var(--primaryColor);
$primaryHover: var(--primaryHover);
$primaryColorA04: var(--primaryColorA07);
$primaryColorA07: var(--primaryColorA07);
$primaryContrast: var(--primaryContrast);
$secondaryColor: var(--secondaryColor);
$secondaryHover: var(--secondaryHover);
$bodyBackground: var(--bodyBackground);
$tabsGray: var(--tabsGray);
$componentBackground: var(--componentBackground);
$contrastBackground: var(--contrastBackground);
$textColor: var(--textColor);
$textColorSecondary: var(--textColorSecondary);
$textColorInvert: var(--textColorInvert);
$textColorHover: var(--textColorHover);
$textColorA015: var(--textColorA015);
$textColorA075: var(--textColorA075);
$borderColor: var(--borderColor);
$borderColorA06: var(--borderColorA06);
$disabledColor: var(--disabledColor);
$disabledColorA04: var(--disabledColorA04);
$disabledColorA06: var(--disabledColorA06);
$itemActiveBackground: var(--itemActiveBackground);
$itemHoverBackground: var(--itemHoverBackground);
$itemActiveColor: var(--itemActiveColor);
$logo: var(--logo);
$logoSmall: var(--logoSmall);
$errorColor: var(--errorColor);
$error100: var(--error100);
$errorA08: var(--errorA08);
$pickerDropdown: var(--pickerDropdown);

$crispBlack: var(--crispBlack);
$basicBlack: var(--basicBlack);

$focusShadow: 0 0 0 5px var(--primaryColorA07);

//font-update
$fontSizeBase: 12px;
