@import '../variables';

$prefix: $antPrefix + -select;

.#{$prefix}-lg {
  font-size: $fontSizeBase;
}

.#{$prefix}-item-option-disabled {
  color: $disabledColor;
}

.#{$prefix}-dropdown {
  background-color: $componentBackground;
  .#{$prefix}-item {
    &-group {
      color: $disabledColor;
    }
    &:not(.#{$prefix}-item-option-disabled):not(.#{$prefix}-item-group) {
      color: $textColor;
      &.#{$prefix}-item-option-selected {
        color: $primaryColor;
        background-color: $itemHoverBackground;
      }
      &:hover {
        background-color: $itemHoverBackground;
      }
    }
  }
}

%disabled {
  &.ant-select-disabled {
    .ant-select-selector {
      color: $disabledColor;
      background: $bodyBackground;
      border-color: $borderColor;
    }
    .ant-select-arrow {
      color: $disabledColor;
    }
  }
}

.#{$prefix}-multiple {
  &:not(.ant-select-disabled) {
    .ant-select-selector {
      color: $textColor;
      background-color: $componentBackground;
      border-color: $borderColor;
    }

    .#{$prefix}-selection-item {
      background: $itemHoverBackground;
      border-color: $itemHoverBackground;
    }
    .#{$prefix}-selection-item-remove {
      color: $disabledColor;
      &:hover {
        color: $primaryColor;
      }
    }
  }
  @extend %disabled;
}

.#{$prefix}-multiple.#{$prefix}-disabled.#{$prefix}:not(.#{$prefix}-customize-input) .#{$prefix}-selector {
  color: $textColor;
  background-color: $componentBackground;
  border-color: $borderColor;
}

.#{$prefix} {
  &.ant-select-single:not(.ant-select-customize-input) {
    .ant-select-selector {
      transition: none;
    }
    &:not(.ant-select-disabled) {
      .ant-select-selector {
        color: $textColor;
        background-color: $componentBackground;
        border-color: $borderColor;
      }
      .ant-select-arrow {
        color: $textColor;
      }
      &.ant-select-open,
      &:hover,
      &:focus,
      &:focus-within {
        .ant-select-selector {
          color: $primaryColor;
          border-color: $primaryColor;
          .ant-select-selection-item {
            opacity: 1;
          }
        }
        .ant-select-arrow {
          color: $primaryColor;
        }
      }
    }
    @extend %disabled;
  }
  &-item-option-active:not(.#{$prefix}-item-option-disabled) {
    background-color: $itemHoverBackground;
  }
  &-clear {
    color: $borderColor;
    background: $componentBackground;
    &:hover {
      color: $primaryColor;
    }
  }
}

input.#{$prefix}-selection-search-input {
  overflow: visible;
  min-width: 40px !important;
}

[data-theme='dark'] {
  .ant-form-item-explain,
  .ant-form-item-extra {
    color: rgba(173, 173, 173, 0.45);
  }
}
