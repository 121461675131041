@import '../variables';

$prefix: $antPrefix + -spin;

.#{$prefix} {
  color: $primaryColor;
  &-dot-item {
    background-color: $primaryColor;
  }
}

.#{$prefix}-nested-loading.no-overlay {
  .ant-spin-container:after {
    display: none !important;
    background-color: transparent !important;
  }
}
