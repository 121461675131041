@import '../variables';

$prefix: $antPrefix + -tooltip;

.#{$prefix}-inner {
  background-color: #000;
}
.ant-tooltip-arrow-content {
  background-color: #000;
}

.#{$prefix}:not(.#{$prefix}-hidden) {
  .ant-typography,
  .ant-tooltip-inner {
    color: #fff;
    color: $textColorInvert;
  }
}

.#{$prefix}.invert {
  .#{$prefix}-inner,
  .ant-tooltip-arrow-content {
    background-color: #fff; // White tooltip
  }

  .#{$prefix}:not(.#{$prefix}-hidden) {
    .ant-typography,
    .ant-tooltip-inner {
      color: #000; // Black text
      color: $textColorInvert;
    }
  }
}

[data-theme='dark'] {
  .#{$prefix}-inner {
    background-color: #fff;
  }
  .ant-tooltip-arrow-content {
    background-color: #fff;
  }
  .#{$prefix}:not(.#{$prefix}-hidden) {
    .ant-typography,
    .ant-tooltip-inner {
      color: #000;
      color: $textColorInvert;
    }
  }
  .#{$prefix}.invert {
    .#{$prefix}-inner,
    .ant-tooltip-arrow-content {
      background-color: #000; // Black tooltip
    }

    .#{$prefix}:not(.#{$prefix}-hidden) {
      .ant-typography,
      .ant-tooltip-inner {
        color: #fff; // White text
        color: $textColorInvert;
      }
    }
  }
}

.#{$prefix}.no-padding .#{$prefix}-inner {
  padding: 0 !important;
}
