@import '../variables';

$prefix: $antPrefix + -slider;

.#{$prefix} {
  .#{$prefix}-rail {
    background-color: $borderColor;
  }
  .#{$prefix}-track {
    background-color: $primaryColorA07;
  }

  &-disabled:hover {
    .#{$prefix}-rail {
      background-color: $borderColor !important;
    }
    .#{$prefix}-track {
      background-color: $disabledColor !important;
    }
  }

  &:not(.#{$prefix}-disabled) {
    &:hover {
      .#{$prefix} {
        &-rail {
          background-color: $borderColor !important;
        }
        &-track {
          background-color: $primaryColor !important;
        }
        &-handle:not(.#{$antPrefix}-tooltip-open) {
          border-color: $primaryColorA07 !important;
        }
      }
    }
  }
  &:not(.#{$prefix}-disabled) .#{$prefix}-handle.#{$antPrefix}-tooltip-open {
    border-color: $primaryColor !important;
  }
  .#{$prefix}-handle:focus {
    box-shadow: $focusShadow !important;
  }
}
