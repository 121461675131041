.container {
  width: 100%;
}
@media (min-width: 480px) {
  .container {
    max-width: 480px;
  }
}
@media (min-width: 576px) {
  .container {
    max-width: 576px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 992px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}
@media (min-width: 1600px) {
  .container {
    max-width: 1600px;
  }
}
.pointer-events-none {
  pointer-events: none !important;
}
.pointer-events-auto {
  pointer-events: auto !important;
}
.\!visible {
  visibility: visible !important;
}
.visible {
  visibility: visible !important;
}
.collapse {
  visibility: collapse !important;
}
.static {
  position: static !important;
}
.fixed {
  position: fixed !important;
}
.absolute {
  position: absolute !important;
}
.relative {
  position: relative !important;
}
.sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}
.bottom-0 {
  bottom: 0px !important;
}
.left-0 {
  left: 0px !important;
}
.right-0 {
  right: 0px !important;
}
.top-0 {
  top: 0px !important;
}
.top-6 {
  top: 1.5rem !important;
}
.z-10 {
  z-index: 10 !important;
}
.col-span-1 {
  grid-column: span 1 / span 1 !important;
}
.col-span-2 {
  grid-column: span 2 / span 2 !important;
}
.col-span-3 {
  grid-column: span 3 / span 3 !important;
}
.col-span-5 {
  grid-column: span 5 / span 5 !important;
}
.float-right {
  float: right !important;
}
.float-left {
  float: left !important;
}
.-m-1 {
  margin: -0.25rem !important;
}
.m-0 {
  margin: 0px !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.m-5 {
  margin: 1.25rem !important;
}
.m-auto {
  margin: auto !important;
}
.-mx-2 {
  margin-left: -0.5rem !important;
  margin-right: -0.5rem !important;
}
.-mx-8 {
  margin-left: -2rem !important;
  margin-right: -2rem !important;
}
.-my-2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}
.mx-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.mx-2 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}
.mx-4 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}
.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}
.my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}
.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
.-mb-1 {
  margin-bottom: -0.25rem !important;
}
.-mb-2 {
  margin-bottom: -0.5rem !important;
}
.-mb-6 {
  margin-bottom: -1.5rem !important;
}
.-ml-1 {
  margin-left: -0.25rem !important;
}
.-ml-2 {
  margin-left: -0.5rem !important;
}
.-mr-1 {
  margin-right: -0.25rem !important;
}
.-mr-2 {
  margin-right: -0.5rem !important;
}
.-mr-3 {
  margin-right: -0.75rem !important;
}
.-mt-1 {
  margin-top: -0.25rem !important;
}
.-mt-10 {
  margin-top: -2.5rem !important;
}
.-mt-2 {
  margin-top: -0.5rem !important;
}
.-mt-4 {
  margin-top: -1rem !important;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.mb-1 {
  margin-bottom: 0.25rem !important;
}
.mb-2 {
  margin-bottom: 0.5rem !important;
}
.mb-20 {
  margin-bottom: 5rem !important;
}
.mb-3 {
  margin-bottom: 0.75rem !important;
}
.mb-4 {
  margin-bottom: 1rem !important;
}
.mb-5 {
  margin-bottom: 1.25rem !important;
}
.mb-6 {
  margin-bottom: 1.5rem !important;
}
.mb-8 {
  margin-bottom: 2rem !important;
}
.ml-0 {
  margin-left: 0px !important;
}
.ml-1 {
  margin-left: 0.25rem !important;
}
.ml-10 {
  margin-left: 2.5rem !important;
}
.ml-2 {
  margin-left: 0.5rem !important;
}
.ml-\[0\.5px\] {
  margin-left: 0.5px !important;
}
.ml-auto {
  margin-left: auto !important;
}
.mr-1 {
  margin-right: 0.25rem !important;
}
.mr-2 {
  margin-right: 0.5rem !important;
}
.mr-3 {
  margin-right: 0.75rem !important;
}
.mr-4 {
  margin-right: 1rem !important;
}
.mt-0 {
  margin-top: 0px !important;
}
.mt-1 {
  margin-top: 0.25rem !important;
}
.mt-2 {
  margin-top: 0.5rem !important;
}
.mt-3 {
  margin-top: 0.75rem !important;
}
.mt-4 {
  margin-top: 1rem !important;
}
.mt-6 {
  margin-top: 1.5rem !important;
}
.mt-8 {
  margin-top: 2rem !important;
}
.block {
  display: block !important;
}
.inline-block {
  display: inline-block !important;
}
.inline {
  display: inline !important;
}
.flex {
  display: flex !important;
}
.inline-flex {
  display: inline-flex !important;
}
.table {
  display: table !important;
}
.table-column {
  display: table-column !important;
}
.grid {
  display: grid !important;
}
.hidden {
  display: none !important;
}
.h-0 {
  height: 0px !important;
}
.h-10 {
  height: 2.5rem !important;
}
.h-12 {
  height: 3rem !important;
}
.h-16 {
  height: 4rem !important;
}
.h-2 {
  height: 0.5rem !important;
}
.h-20 {
  height: 5rem !important;
}
.h-4 {
  height: 1rem !important;
}
.h-40 {
  height: 10rem !important;
}
.h-5 {
  height: 1.25rem !important;
}
.h-6 {
  height: 1.5rem !important;
}
.h-8 {
  height: 2rem !important;
}
.h-auto {
  height: auto !important;
}
.h-full {
  height: 100% !important;
}
.h-screen {
  height: 100vh !important;
}
.min-h-\[40px\] {
  min-height: 40px !important;
}
.min-h-screen {
  min-height: 100vh !important;
}
.w-0 {
  width: 0px !important;
}
.w-1\/3 {
  width: 33.333333% !important;
}
.w-1\/4 {
  width: 25% !important;
}
.w-10 {
  width: 2.5rem !important;
}
.w-12 {
  width: 3rem !important;
}
.w-16 {
  width: 4rem !important;
}
.w-2\/3 {
  width: 66.666667% !important;
}
.w-20 {
  width: 5rem !important;
}
.w-3 {
  width: 0.75rem !important;
}
.w-4 {
  width: 1rem !important;
}
.w-4\/5 {
  width: 80% !important;
}
.w-40 {
  width: 10rem !important;
}
.w-48 {
  width: 12rem !important;
}
.w-6 {
  width: 1.5rem !important;
}
.w-64 {
  width: 16rem !important;
}
.w-8 {
  width: 2rem !important;
}
.w-auto {
  width: auto !important;
}
.w-full {
  width: 100% !important;
}
.w-screen-md {
  width: 768px !important;
}
.w-screen-sm {
  width: 576px !important;
}
.w-screen-xs {
  width: 480px !important;
}
.w-sm {
  width: 24rem !important;
}
.min-w-40 {
  min-width: 10rem !important;
}
.min-w-6 {
  min-width: 1.5rem !important;
}
.min-w-screen-sm {
  min-width: 576px !important;
}
.max-w-2xl {
  max-width: 42rem !important;
}
.max-w-3xl {
  max-width: 48rem !important;
}
.max-w-5xl {
  max-width: 64rem !important;
}
.max-w-64 {
  max-width: 16rem !important;
}
.max-w-80 {
  max-width: 20rem !important;
}
.max-w-\[300px\] {
  max-width: 300px !important;
}
.max-w-\[400px\] {
  max-width: 400px !important;
}
.max-w-full {
  max-width: 100% !important;
}
.max-w-lg {
  max-width: 32rem !important;
}
.max-w-md {
  max-width: 28rem !important;
}
.max-w-screen {
  max-width: 100vw !important;
}
.max-w-screen-sm {
  max-width: 576px !important;
}
.max-w-sm {
  max-width: 24rem !important;
}
.max-w-xl {
  max-width: 36rem !important;
}
.max-w-xs {
  max-width: 20rem !important;
}
.flex-1 {
  flex: 1 1 0% !important;
}
.flex-shrink {
  flex-shrink: 1 !important;
}
.flex-shrink-0 {
  flex-shrink: 0 !important;
}
.flex-grow {
  flex-grow: 1 !important;
}
.-translate-y-12 {
  --tw-translate-y: -3rem !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.translate-x-12 {
  --tw-translate-x: 3rem !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.-rotate-45 {
  --tw-rotate: -45deg !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.rotate-45 {
  --tw-rotate: 45deg !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.transform {
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.cursor-default {
  cursor: default !important;
}
.cursor-move {
  cursor: move !important;
}
.cursor-not-allowed {
  cursor: not-allowed !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
.cursor-text {
  cursor: text !important;
}
.cursor-wait {
  cursor: wait !important;
}
.select-none {
  -webkit-user-select: none !important;
          user-select: none !important;
}
.select-all {
  -webkit-user-select: all !important;
          user-select: all !important;
}
.resize {
  resize: both !important;
}
.list-none {
  list-style-type: none !important;
}
.grid-flow-col {
  grid-auto-flow: column !important;
}
.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
}
.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
}
.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
}
.flex-row {
  flex-direction: row !important;
}
.flex-col {
  flex-direction: column !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.place-items-center {
  place-items: center !important;
}
.items-start {
  align-items: flex-start !important;
}
.items-center {
  align-items: center !important;
}
.justify-end {
  justify-content: flex-end !important;
}
.justify-center {
  justify-content: center !important;
}
.justify-between {
  justify-content: space-between !important;
}
.justify-around {
  justify-content: space-around !important;
}
.gap-0 {
  gap: 0px !important;
}
.gap-1 {
  gap: 0.25rem !important;
}
.gap-2 {
  gap: 0.5rem !important;
}
.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}
.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(1rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse))) !important;
}
.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse)) !important;
}
.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(0.75rem * var(--tw-space-y-reverse)) !important;
}
.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(1rem * var(--tw-space-y-reverse)) !important;
}
.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse)) !important;
}
.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(2rem * var(--tw-space-y-reverse)) !important;
}
.divide-x > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0 !important;
  border-right-width: calc(1px * var(--tw-divide-x-reverse)) !important;
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse))) !important;
}
.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0 !important;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse))) !important;
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse)) !important;
}
.divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(209 213 219 / var(--tw-divide-opacity)) !important;
}
.self-end {
  align-self: flex-end !important;
}
.overflow-auto {
  overflow: auto !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
.overflow-clip {
  overflow: clip !important;
}
.overflow-visible {
  overflow: visible !important;
}
.overflow-y-auto {
  overflow-y: auto !important;
}
.overflow-x-hidden {
  overflow-x: hidden !important;
}
.overflow-y-scroll {
  overflow-y: scroll !important;
}
.truncate {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}
.overflow-ellipsis {
  text-overflow: ellipsis !important;
}
.whitespace-normal {
  white-space: normal !important;
}
.whitespace-pre-line {
  white-space: pre-line !important;
}
.whitespace-pre-wrap {
  white-space: pre-wrap !important;
}
.break-all {
  word-break: break-all !important;
}
.rounded {
  border-radius: 0.25rem !important;
}
.rounded-full {
  border-radius: 9999px !important;
}
.rounded-lg {
  border-radius: 0.5rem !important;
}
.rounded-md {
  border-radius: 0.375rem !important;
}
.rounded-none {
  border-radius: 0px !important;
}
.rounded-sm {
  border-radius: 0.125rem !important;
}
.rounded-b-md {
  border-bottom-right-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important;
}
.rounded-r-md {
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
}
.rounded-r-none {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.rounded-t-none {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}
.border {
  border-width: 1px !important;
}
.border-0 {
  border-width: 0px !important;
}
.border-b {
  border-bottom-width: 1px !important;
}
.border-b-0 {
  border-bottom-width: 0px !important;
}
.border-l-0 {
  border-left-width: 0px !important;
}
.border-r {
  border-right-width: 1px !important;
}
.border-r-0 {
  border-right-width: 0px !important;
}
.border-t-0 {
  border-top-width: 0px !important;
}
.border-t-4 {
  border-top-width: 4px !important;
}
.border-solid {
  border-style: solid !important;
}
.border-dashed {
  border-style: dashed !important;
}
.border-none {
  border-style: none !important;
}
.border-error-A08 {
  border-color: var(--errorA08) !important;
}
.border-gray-600 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(75 85 99 / var(--tw-border-opacity)) !important;
}
.border-orange-500 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(249 115 22 / var(--tw-border-opacity)) !important;
}
.border-transparent {
  border-color: transparent !important;
}
.border-vars-bodyBackground {
  border-color: var(--bodyBackground) !important;
}
.border-vars-borderColor {
  border-color: var(--borderColor) !important;
}
.border-vars-contrastBackground {
  border-color: var(--contrastBackground) !important;
}
.border-vars-disabledColor {
  border-color: var(--disabledColor) !important;
}
.border-vars-primaryColor {
  border-color: var(--primaryColor) !important;
}
.border-warning-0 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(255 191 0 / var(--tw-border-opacity)) !important;
}
.bg-black {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity)) !important;
}
.bg-blue-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity)) !important;
}
.bg-error-A08 {
  background-color: var(--errorA08) !important;
}
.bg-gray-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity)) !important;
}
.bg-gray-200 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity)) !important;
}
.bg-orange-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 237 213 / var(--tw-bg-opacity)) !important;
}
.bg-red-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity)) !important;
}
.bg-secondary-800 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(38 38 38 / var(--tw-bg-opacity)) !important;
}
.bg-transparent {
  background-color: transparent !important;
}
.bg-vars-basicBlack {
  background-color: var(--basicBlack) !important;
}
.bg-vars-bodyBackground {
  background-color: var(--bodyBackground) !important;
}
.bg-vars-borderColor {
  background-color: var(--borderColor) !important;
}
.bg-vars-componentBackground {
  background-color: var(--componentBackground) !important;
}
.bg-vars-contrastBackground {
  background-color: var(--contrastBackground) !important;
}
.bg-white {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}
.bg-yellow-50 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(254 252 232 / var(--tw-bg-opacity)) !important;
}
.bg-gradient-to-t {
  background-image: linear-gradient(to top, var(--tw-gradient-stops)) !important;
}
.from-white {
  --tw-gradient-from: #fff !important;
  --tw-gradient-to: rgb(255 255 255 / 0) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}
.bg-contain {
  background-size: contain !important;
}
.bg-cover {
  background-size: cover !important;
}
.bg-center {
  background-position: center !important;
}
.bg-left {
  background-position: left !important;
}
.bg-no-repeat {
  background-repeat: no-repeat !important;
}
.fill-current {
  fill: currentColor !important;
}
.object-contain {
  object-fit: contain !important;
}
.object-cover {
  object-fit: cover !important;
}
.p-0 {
  padding: 0px !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.p-3 {
  padding: 0.75rem !important;
}
.p-4 {
  padding: 1rem !important;
}
.p-5 {
  padding: 1.25rem !important;
}
.p-6 {
  padding: 1.5rem !important;
}
.p-8 {
  padding: 2rem !important;
}
.px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}
.px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
.px-3 {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}
.px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.px-6 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
.px-8 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}
.py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}
.py-10 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}
.py-12 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.py-3 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}
.py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.py-5 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}
.py-6 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}
.pb-0 {
  padding-bottom: 0px !important;
}
.pb-1 {
  padding-bottom: 0.25rem !important;
}
.pb-2 {
  padding-bottom: 0.5rem !important;
}
.pb-4 {
  padding-bottom: 1rem !important;
}
.pb-6 {
  padding-bottom: 1.5rem !important;
}
.pl-0 {
  padding-left: 0px !important;
}
.pl-2 {
  padding-left: 0.5rem !important;
}
.pl-3 {
  padding-left: 0.75rem !important;
}
.pl-5 {
  padding-left: 1.25rem !important;
}
.pl-8 {
  padding-left: 2rem !important;
}
.pr-0 {
  padding-right: 0px !important;
}
.pr-1 {
  padding-right: 0.25rem !important;
}
.pr-2 {
  padding-right: 0.5rem !important;
}
.pr-3 {
  padding-right: 0.75rem !important;
}
.pr-8 {
  padding-right: 2rem !important;
}
.pt-0 {
  padding-top: 0px !important;
}
.pt-1 {
  padding-top: 0.25rem !important;
}
.pt-12 {
  padding-top: 3rem !important;
}
.pt-2 {
  padding-top: 0.5rem !important;
}
.pt-3 {
  padding-top: 0.75rem !important;
}
.pt-4 {
  padding-top: 1rem !important;
}
.pt-6 {
  padding-top: 1.5rem !important;
}
.pt-8 {
  padding-top: 2rem !important;
}
.text-left {
  text-align: left !important;
}
.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}
.align-baseline {
  vertical-align: baseline !important;
}
.align-top {
  vertical-align: top !important;
}
.align-middle {
  vertical-align: middle !important;
}
.text-2xl {
  font-size: 1.5em !important;
}
.text-3xl {
  font-size: 1.875em !important;
}
.text-4xl {
  font-size: 2.25em !important;
}
.text-5xl {
  font-size: 3em !important;
}
.text-6xl {
  font-size: 4em !important;
}
.text-lg {
  font-size: 1.125em !important;
}
.text-lg-title {
  font-size: 36px !important;
}
.text-size-label {
  font-size: 10px !important;
}
.text-sm {
  font-size: 0.975em !important;
}
.text-xl {
  font-size: 1.25em !important;
}
.text-xs {
  font-size: 0.80em !important;
}
.font-bold {
  font-weight: 700 !important;
}
.font-light {
  font-weight: 300 !important;
}
.font-medium {
  font-weight: 500 !important;
}
.font-normal {
  font-weight: 400 !important;
}
.font-semibold {
  font-weight: 600 !important;
}
.uppercase {
  text-transform: uppercase !important;
}
.lowercase {
  text-transform: lowercase !important;
}
.capitalize {
  text-transform: capitalize !important;
}
.italic {
  font-style: italic !important;
}
.tabular-nums {
  --tw-numeric-spacing: tabular-nums !important;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction) !important;
}
.leading-3 {
  line-height: .75rem !important;
}
.leading-4 {
  line-height: 1rem !important;
}
.leading-relaxed {
  line-height: 1.625 !important;
}
.leading-tight {
  line-height: 1.25 !important;
}
.text-current {
  color: currentColor !important;
}
.text-error {
  --tw-text-opacity: 1 !important;
  color: rgb(246 71 68 / var(--tw-text-opacity)) !important;
}
.text-error-0 {
  --tw-text-opacity: 1 !important;
  color: rgb(246 71 68 / var(--tw-text-opacity)) !important;
}
.text-gray-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(156 163 175 / var(--tw-text-opacity)) !important;
}
.text-gray-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(107 114 128 / var(--tw-text-opacity)) !important;
}
.text-green-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(74 222 128 / var(--tw-text-opacity)) !important;
}
.text-green-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(34 197 94 / var(--tw-text-opacity)) !important;
}
.text-green-900 {
  --tw-text-opacity: 1 !important;
  color: rgb(20 83 45 / var(--tw-text-opacity)) !important;
}
.text-info {
  --tw-text-opacity: 1 !important;
  color: rgb(45 183 245 / var(--tw-text-opacity)) !important;
}
.text-orange-700 {
  --tw-text-opacity: 1 !important;
  color: rgb(194 65 12 / var(--tw-text-opacity)) !important;
}
.text-primary {
  color: var(--primaryColor) !important;
}
.text-primary-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(67 168 81 / var(--tw-text-opacity)) !important;
}
.text-red-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(248 113 113 / var(--tw-text-opacity)) !important;
}
.text-red-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(239 68 68 / var(--tw-text-opacity)) !important;
}
.text-red-800 {
  --tw-text-opacity: 1 !important;
  color: rgb(153 27 27 / var(--tw-text-opacity)) !important;
}
.text-success {
  --tw-text-opacity: 1 !important;
  color: rgb(76 183 92 / var(--tw-text-opacity)) !important;
}
.text-teal-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(20 184 166 / var(--tw-text-opacity)) !important;
}
.text-vars-contrastBackground {
  color: var(--contrastBackground) !important;
}
.text-vars-disabledColor {
  color: var(--disabledColor) !important;
}
.text-vars-error100 {
  color: var(--error100) !important;
}
.text-vars-errorColor {
  color: var(--errorColor) !important;
}
.text-vars-primaryColor {
  color: var(--primaryColor) !important;
}
.text-vars-secondaryColor {
  color: var(--secondaryColor) !important;
}
.text-vars-tabsGray {
  color: var(--tabsGray) !important;
}
.text-vars-textColor {
  color: var(--textColor) !important;
}
.text-vars-textColorSecondary {
  color: var(--textColorSecondary) !important;
}
.text-vars-warningText {
  color: var(--warningText) !important;
}
.text-warning {
  --tw-text-opacity: 1 !important;
  color: rgb(255 191 0 / var(--tw-text-opacity)) !important;
}
.text-white {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}
.text-yellow-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(202 138 4 / var(--tw-text-opacity)) !important;
}
.text-yellow-700 {
  --tw-text-opacity: 1 !important;
  color: rgb(161 98 7 / var(--tw-text-opacity)) !important;
}
.underline {
  text-decoration-line: underline !important;
}
.no-underline {
  text-decoration-line: none !important;
}
.opacity-0 {
  opacity: 0 !important;
}
.opacity-100 {
  opacity: 1 !important;
}
.opacity-20 {
  opacity: 0.2 !important;
}
.opacity-25 {
  opacity: 0.25 !important;
}
.opacity-40 {
  opacity: 0.4 !important;
}
.opacity-5 {
  opacity: 0.05 !important;
}
.opacity-50 {
  opacity: 0.5 !important;
}
.opacity-60 {
  opacity: 0.6 !important;
}
.opacity-70 {
  opacity: 0.7 !important;
}
.opacity-75 {
  opacity: 0.75 !important;
}
.shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px 0 var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -1px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.shadow-none {
  --tw-shadow: 0 0 #0000 !important;
  --tw-shadow-colored: 0 0 #0000 !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.outline-none {
  outline: 2px solid transparent !important;
  outline-offset: 2px !important;
}
.outline {
  outline-style: solid !important;
}
.blur {
  --tw-blur: blur(8px) !important;
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
          filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}
.drop-shadow {
  --tw-drop-shadow: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1)) drop-shadow(0 1px 1px rgb(0 0 0 / 0.06)) !important;
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
          filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}
.grayscale {
  --tw-grayscale: grayscale(100%) !important;
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
          filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}
.invert {
  --tw-invert: invert(100%) !important;
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
          filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}
.filter {
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
          filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}
.backdrop-filter {
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}
.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, -webkit-transform, -webkit-filter, -webkit-backdrop-filter !important;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter !important;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-transform, -webkit-filter, -webkit-backdrop-filter !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
}
.transition-all {
  transition-property: all !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
}
.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
}
.transition-none {
  transition-property: none !important;
}
.duration-500 {
  transition-duration: 500ms !important;
}
.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
}

.form-item-label {
  font-size: 0.625rem;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--tabsGray);
  line-height: 1.25;
}

body {
  overflow-wrap: break-word;
  font-size: 12px;
  font-weight: 300;
  line-height: 1.25;
  color: var(--textColor);
}

.ant-form .form-item-label label {
  font-size: 10px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  /* sets heading color */
  color: var(--textColor);
}

.flex-space:not(.flex-col) > *:not(:last-child) {
  padding-right: 0.5rem;
}
.flex-space.flex-col > *:not(:last-child) {
  padding-bottom: 0.5rem;
}

button {
  background-color: transparent;
  border-style: none;
  cursor: pointer;
}

.transition-all-default {
  transition: all 0.3s ease;
}

.place-center {
  place-items: center;
}

.center-x {
  left: 50%;
  -webkit-transform: translateX(-50);
          transform: translateX(-50);
}
.center-y {
  left: 50%;
  -webkit-transform: translateX(-50);
          transform: translateX(-50);
  top: 50%;
  -webkit-transform: translateY(-50);
          transform: translateY(-50);
}
.center-xy,
.center-yx {
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50);
          transform: translate(-50%, -50);
}

.transition-all {
  transition: all 0.3s ease;
}

.hover\:border-vars-borderColor:hover {
  border-color: var(--borderColor) !important;
}

.hover\:border-vars-disabledColor:hover {
  border-color: var(--disabledColor) !important;
}

.hover\:border-vars-primaryColor:hover {
  border-color: var(--primaryColor) !important;
}

.hover\:bg-gray-900:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity)) !important;
}

.hover\:bg-primary-100:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(232 246 234 / var(--tw-bg-opacity)) !important;
}

.hover\:bg-primary-600:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(67 168 81 / var(--tw-bg-opacity)) !important;
}

.hover\:bg-vars-contrastBackground:hover {
  background-color: var(--contrastBackground) !important;
}

.hover\:bg-vars-itemHoverBackground:hover {
  background-color: var(--itemHoverBackground) !important;
}

.hover\:text-primary:hover {
  color: var(--primaryColor) !important;
}

.hover\:text-primary-600:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(67 168 81 / var(--tw-text-opacity)) !important;
}

.hover\:text-red-700:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(185 28 28 / var(--tw-text-opacity)) !important;
}

.hover\:text-red-900:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(127 29 29 / var(--tw-text-opacity)) !important;
}

.hover\:text-vars-primaryColor:hover {
  color: var(--primaryColor) !important;
}

.hover\:text-white:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.hover\:opacity-100:hover {
  opacity: 1 !important;
}

.hover\:shadow-lg:hover {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -2px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.focus\:border-vars-borderColor:focus {
  border-color: var(--borderColor) !important;
}

.group:hover .group-hover\:underline {
  text-decoration-line: underline !important;
}

.dark .dark\:border-gray-300 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(209 213 219 / var(--tw-border-opacity)) !important;
}

.dark .dark\:bg-orange-900 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(124 45 18 / var(--tw-bg-opacity)) !important;
}

.dark .dark\:bg-vars-contrastBackground {
  background-color: var(--contrastBackground) !important;
}

.dark .dark\:bg-white {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}

.dark .dark\:text-gray-300 {
  --tw-text-opacity: 1 !important;
  color: rgb(209 213 219 / var(--tw-text-opacity)) !important;
}

.dark .dark\:text-green-200 {
  --tw-text-opacity: 1 !important;
  color: rgb(187 247 208 / var(--tw-text-opacity)) !important;
}

.dark .dark\:text-green-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(74 222 128 / var(--tw-text-opacity)) !important;
}

.dark .dark\:text-orange-300 {
  --tw-text-opacity: 1 !important;
  color: rgb(253 186 116 / var(--tw-text-opacity)) !important;
}

.dark .dark\:text-red-200 {
  --tw-text-opacity: 1 !important;
  color: rgb(254 202 202 / var(--tw-text-opacity)) !important;
}

.dark .dark\:text-red-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(248 113 113 / var(--tw-text-opacity)) !important;
}

.dark .dark\:text-white {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.dark .dark\:hover\:text-red-100:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(254 226 226 / var(--tw-text-opacity)) !important;
}

@media (min-width: 576px) {
  .sm\:col-span-2 {
    grid-column: span 2 / span 2 !important;
  }
  .sm\:ml-0 {
    margin-left: 0px !important;
  }
  .sm\:block {
    display: block !important;
  }
  .sm\:grid {
    display: grid !important;
  }
}

@media (min-width: 768px) {
  .md\:w-1\/2 {
    width: 50% !important;
  }
  .md\:w-8 {
    width: 2rem !important;
  }
  .md\:min-w-8 {
    min-width: 2rem !important;
  }
  .md\:text-2xl {
    font-size: 1.5em !important;
  }
  .md\:text-4xl {
    font-size: 2.25em !important;
  }
  .md\:text-5xl {
    font-size: 3em !important;
  }
  .md\:text-md {
    font-size: 1em !important;
  }
}

@media (min-width: 992px) {
  .lg\:w-1\/4 {
    width: 25% !important;
  }
  .lg\:px-32 {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }
  .lg\:py-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
}
