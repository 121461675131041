@import '../variables';

$prefix: $antPrefix + -tree;

.#{$prefix} {
  background: $componentBackground;
  color: $textColor;
}

.ant-tree-select-dropdown .ant-select-tree {
  background: $componentBackground;
  color: $textColor;
}

[data-theme='dark'] {
  .ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
    background: $itemHoverBackground;
  }
}
