@import '../variables';

$prefix: $antPrefix + -form;

.#{$prefix} {
  &-vertical .#{$prefix}-item-label,
  .#{$antPrefix}-col-24.#{$prefix}-item-label,
  .#{$antPrefix}-col-xl-24.#{$prefix}-item-label {
    padding: 0;
  }
  &-item-label > label {
    color: $tabsGray;
    height: auto !important;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 20px;
  }
  &-item-control:not(.has-error) {
    .#{$prefix}-explain {
      color: $disabledColor;
    }
  }
  &-item {
    margin-bottom: 10px;
  }
}

.required-after .#{$prefix}-item-label > label.#{$prefix}-item-required {
  &::before {
    display: none;
  }
  &::after {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: $fontSizeBase;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
}

.#{$prefix}-item.uppercase-label {
  .#{$prefix}-item-label {
    text-transform: uppercase;
  }
}
