@import '../variables';

$prefix: $antPrefix + -picker-time;

.#{$prefix} {
  &-panel-column > li.#{$prefix}-panel-cell .#{$prefix}-panel-cell-inner {
    color: $textColor;
  }
  &-panel-column > li.#{$prefix}-panel-cell-disabled .#{$prefix}-panel-cell-inner {
    color: $disabledColorA06 !important;
  }
  &-panel-column:not(:first-child) {
    border-left-color: $borderColor;
  }
}

.has-error {
  .#{$prefix} {
    &-input {
      border-color: $errorColor;
      &:hover {
        border-color: $error100;
      }
    }
    &-icon i {
      color: $errorColor;
    }
    &:hover {
      .#{$prefix}-icon i {
        color: $error100;
      }
    }
  }
}
