@import '../variables';

$prefix: 'ant-breadcrumb';

.#{$prefix} {
  color: $textColor !important;
  .ant-breadcrumb-link > span {
    color: $textColor !important;
    opacity: 0.8;
  }

  .ant-breadcrumb-link a {
    color: $textColor !important;
    &:hover {
      color: $primaryHover !important;
    }
    &:active {
      color: $primaryColor !important;
    }
  }
  .ant-breadcrumb-separator {
    color: $disabledColor !important;
  }
}
