@import '../variables';

$prefix: $antPrefix + -badge;

.#{$prefix} {
  color: $textColor !important;
  &-status-text {
    color: $textColor !important;
  }
}
