@import '../variables';

$prefix: $antPrefix + -typography;

.#{$prefix}:not(a) {
  color: $textColor;
}

.#{$prefix}.#{$prefix}-disabled {
  color: $disabledColor;
}

div.#{$prefix},
.#{$prefix} p {
  margin-bottom: auto;
}

// //title
// h1.#{$prefix} {
// }
// //link
// a.#{$prefix} {
// }
// //pargraph
// div.#{$prefix} {
// }
// //text
// span.#{$prefix} {
// }
