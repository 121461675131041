@import '../variables';

$prefix: $antPrefix + -descriptions;

.#{$prefix} {
  &-bordered {
    .#{$prefix} {
      &-view,
      &-row,
      &-item-label,
      &-item-content {
        border-color: $contrastBackground !important;
      }
      &-item-label {
        background: $contrastBackground !important;
      }
    }
  }
  &-item-label,
  &-item-content {
    color: $textColor !important;
  }
}
