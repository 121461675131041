@import '../variables';

$prefix: $antPrefix + -alert;

.#{$prefix} {
  &-message,
  &-description {
    color: $textColor;
  }

  &-with-description .#{$prefix} {
    &-message,
    &-description {
      color: $textColor;
    }
  }
}
