@import '../variables';

$prefix: $antPrefix + -input;

.#{$prefix}-lg,
.#{$prefix}-number-lg {
  font-size: $fontSizeBase;
  line-height: 1.8em !important;
}

.#{$prefix}-group-addon {
  background: transparent;
}

%inputBaseStyle {
  color: $textColor;
  background-color: $componentBackground !important;
  border-color: $borderColor;
  &-lg {
    font-size: $fontSizeBase;
    padding: 10px 11px;
  }
  &:hover,
  &:focus,
  &:focus-within,
  &-focused {
    border-color: $primaryColor;
  }
}

%disabledStyle {
  color: $disabledColor;
  background-color: $bodyBackground !important;
  border-color: $borderColor;
  &:hover {
    border-color: $borderColor;
  }
}

%errorStyle {
  .#{$prefix},
  .#{$prefix}-affix-wrapper,
  .#{$prefix}:hover,
  .#{$prefix}-affix-wrapper:hover {
    background-color: $componentBackground;
  }
}

%placeholderColor {
  &::placeholder {
    color: $textColorA075 !important;
  }
}
%disabledPlaceholderColor {
  &::placeholder {
    color: $disabledColorA04 !important;
  }
}

.#{$prefix} {
  @extend %inputBaseStyle;
  @extend %placeholderColor;
}
.#{$prefix}-affix-wrapper:not(.#{$prefix}-affix-wrapper-disabled):hover {
  border-color: $primaryColor;
}
.#{$prefix}-affix-wrapper {
  &:not(.#{$prefix}-affix-wrapper-disabled) {
    @extend %inputBaseStyle;
  }
  &-disabled {
    @extend %disabledStyle;
    .#{$prefix}-search-icon,
    .#{$prefix}-clear-icon {
      cursor: not-allowed;
      color: $disabledColor;
      &:hover {
        color: $disabledColor;
      }
    }
    input:disabled {
      background-color: $bodyBackground;
      @extend %disabledPlaceholderColor;
    }
  }
}
.#{$prefix}-search-icon.anticon-loading:before {
  display: none;
}
.#{$prefix}-search-icon,
.#{$prefix}-clear-icon {
  color: $textColor;
  &:hover {
    color: $primaryColor;
  }
}

.#{$prefix}[disabled],
.#{$prefix}.#{$prefix}-disabled {
  @extend %disabledStyle;
  @extend %disabledPlaceholderColor;
}

.#{$antPrefix}-form-item-has-error {
  @extend %errorStyle;
}

.has-error {
  .#{$prefix} {
    border-color: $errorColor;
    &:hover {
      border-color: $error100;
    }
    &-affix-wrapper {
      color: $errorColor;
      i {
        color: $errorColor;
      }
      &:hover,
      &:focus-within {
        .#{$prefix}:not(.#{$prefix}-disabled) {
          border-color: $errorColor;
        }
      }
    }
  }
}

.#{$prefix}-number {
  .#{$prefix}-number-handler-wrap {
    background: $bodyBackground;
  }

  .#{$prefix}-number-handler:active {
    background: $primaryColorA07;
  }

  .#{$prefix}-number-handler,
  .#{$prefix}-number-handler-up-inner,
  .#{$prefix}-number-handler-down-inner {
    color: $textColor;
  }

  .#{$prefix}-number-handler:hover .#{$prefix}-number-handler-up-inner,
  .#{$prefix}-number-handler:hover .#{$prefix}-number-handler-down-inner {
    color: $primaryColor;
  }

  &.#{$prefix}-number-lg {
    font-size: $fontSizeBase;
  }

  &:not(.#{$prefix}-number-disabled) {
    @extend %inputBaseStyle;
  }
  &.#{$prefix}-number-disabled {
    @extend %disabledStyle;
    input:disabled {
      background-color: $bodyBackground;
      @extend %disabledPlaceholderColor;
    }
  }
  &-input {
    @extend %placeholderColor;
  }

  &-group-addon {
    background: $bodyBackground !important;
    color: $textColor !important;
    border-color: $borderColor !important;
  }
}

input {
  @extend %placeholderColor;
  &:disabled {
    @extend %disabledPlaceholderColor;
  }
}
