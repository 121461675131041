@import '../variables';

$prefix: $antPrefix + -empty;

.#{$prefix} {
  color: $disabledColor;
}

// SIMPLE
.#{$prefix}-img-simple-g {
  stroke: $disabledColor;
}

.#{$prefix}-img-simple-path,
.#{$prefix}-img-simple-ellipse {
  fill: $contrastBackground;
}

// DEFAULT

.#{$prefix}-img-default-g {
  fill: $contrastBackground;
}
.#{$prefix}-img-default-ellipse {
  fill: $crispBlack;
}
.#{$prefix}-img-default-path-1 {
  fill: $crispBlack;
}
.#{$prefix}-img-default-path-3 {
  fill: $disabledColor;
}
.#{$prefix}-img-default-path-4 {
  fill: $contrastBackground;
}
.#{$prefix}-img-default-path-5 {
  fill: $disabledColor;
}
