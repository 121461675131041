@import '../variables';

$prefix: $antPrefix + -tag;

.#{$prefix},
.#{$prefix} a,
.#{$prefix} a:hover {
  color: $textColor !important;
}

.#{$prefix}-success {
  color: #52c41a !important;
  background: #f6ffed !important;
  border-color: #b7eb8f !important;
}
.#{$prefix}-processing {
  color: #1890ff !important;
  background: #e6f7ff !important;
  border-color: #91d5ff !important;
}
.#{$prefix}-error {
  color: #f5222d !important;
  background: #fff1f0 !important;
  border-color: #ffa39e !important;
}
.#{$prefix}-warning {
  color: #fa8c16 !important;
  background: #fff7e6 !important;
  border-color: #ffd591 !important;
}

.ant-tag-close-icon {
  color: $textColor !important;
}

[data-theme='dark'] {
  .#{$prefix} {
    background: $contrastBackground !important;
  }
  .#{$prefix}-success {
    color: #49aa19 !important;
    background: #162312 !important;
    border-color: #274916 !important;
  }
  .#{$prefix}-processing {
    color: #177ddc !important;
    background: #111d2c !important;
    border-color: #15395b !important;
  }
  .#{$prefix}-error {
    color: #d32029 !important;
    background: #2a1215 !important;
    border-color: #58181c !important;
  }
  .#{$prefix}-warning {
    color: #d87a16 !important;
    background: #2b1d11 !important;
    border-color: #593815 !important;
  }
}
