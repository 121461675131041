@import './components/index.scss';

:root {
  --scss: 'v0.0.1';
}

.auth0-lock-input::placeholder {
  color: #8b858e;
}

.intercom-lightweight-app {
  z-index: 999 !important;
}

::marker {
  color: $textColorSecondary;
}

html {
  background: $componentBackground;
}
