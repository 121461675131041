@import '../variables';

$prefix: $antPrefix + -card;

.#{$prefix} {
  background: $componentBackground !important;
  color: $textColor !important;
  &-meta-title,
  &-meta-description {
    color: $textColor !important;
  }
  &-bordered {
    border-color: $borderColor !important;
  }
}
