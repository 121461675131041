@import '../variables';

$prefix: $antPrefix + -modal;
$drawerPrefix: $antPrefix + -drawer;
$modalTypes: '&-confirm, &-info, &-success, &-error, &-warning';

.#{$prefix},
.#{$drawerPrefix} {
  color: $textColor !important;

  &-wrap.no-padding,
  &.no-padding {
    .#{$prefix} {
      &-body {
        padding: 0 !important;
      }
      &-content {
        overflow: hidden !important;
      }
    }
  }

  &-content {
    background-color: $componentBackground !important;
  }
  &-close {
    color: $textColorSecondary;
    &:hover {
      color: $textColor !important;
    }
  }
  &-title {
    color: $textColor !important;
  }
  &-header,
  &-footer {
    color: $textColor !important;
    background: transparent !important;
    border-color: $borderColor !important;
  }

  #{$modalTypes} {
    &-body .#{$prefix} {
      #{$modalTypes} {
        &-title,
        &-content {
          color: $textColor !important;
        }
      }
    }
  }
  &-confirm-info .#{$prefix}-confirm-body > .anticon {
    color: $primaryColor !important;
  }
}
