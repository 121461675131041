@import '../variables';

$prefix: $antPrefix + -btn;

.#{$prefix}.#{$prefix} {
  &::before {
    background: $bodyBackground;
  }

  & > svg {
    transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  & > svg + span,
  & > span + svg {
    margin-left: 8px;
  }

  &-lg {
    font-size: $fontSizeBase;
  }
  &-sm {
    line-height: 100%;
  }
}

.ant-btn > .ant-btn-loading-icon .anticon {
  -webkit-animation: none;
  animation: none;
}

.ant-btn > .ant-btn-loading-icon .anticon svg {
  animation: loadingCircle 1s linear infinite;
}

.ant-btn {
  &:not(.ant-btn-primary):not(.ant-btn-text):not(.ant-btn-link):not(.ant-btn-dangerous):not(.ant-btn-background-ghost):not([disabled]) {
    border-color: $borderColor;
    background: transparent;
  }

  &[disabled]:not(.ant-btn-text):not(.ant-btn-link):not(.ant-btn-background-ghost) {
    border-color: $borderColor;
    background: $disabledColorA06;
    color: $textColorA075;
  }
  &[disabled].ant-btn-background-ghost {
    border-color: $borderColor;
    background: transparent;
    color: $disabledColor;
  }

  &:not(.ant-btn-primary):not(.ant-btn-text):not(.ant-btn-link):not(.ant-btn-dangerous):not(.ant-btn-background-ghost):not([disabled]) {
    color: $textColor;
    &:hover,
    &:focus {
      border-color: $primaryColor;
      color: $primaryColor;
    }
  }
}

.ant-btn-text {
  color: $textColor;
  &:hover,
  &:focus {
    color: $textColor;
    background-color: $disabledColorA04;
  }
}

.ant-btn-text,
.ant-btn-link,
.ant-btn-dangerous,
.ant-btn-dangerous.ant-btn-text,
.ant-btn-dangerous.ant-btn-link {
  &[disabled],
  &[disabled]:hover,
  &[disabled]:focus,
  &[disabled]:active {
    color: $disabledColor;
  }
}

.ant-btn-dangerous {
  &[disabled],
  &[disabled]:hover,
  &[disabled]:focus,
  &[disabled]:active {
    border-color: $borderColorA06;
  }
  &,
  &:hover,
  &:focus {
    background: transparent;
  }
}

.ant-btn.ant-btn-background-ghost {
  border-color: $borderColor;
  color: $textColor;
  &:not([disabled]) {
    &:hover,
    &:focus {
      border-color: $primaryColor;
    }
  }
}
